import React, { useState, useEffect } from 'react';
import Slideshow from './components/SlideShow'; // your slideshow component

import logo from './logo.png';
import './App.css';

function CountdownTimer({ targetDate }) {
  const [timeRemaining, setTimeRemaining] = useState(targetDate - new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeRemaining = targetDate - new Date();
      setTimeRemaining(newTimeRemaining);
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  return (
    <div className="countdown-timer">
      <div className="timer-element">{days} <span>D</span></div>
      <div className="timer-element">{hours} <span>H</span></div>
      <div className="timer-element">{minutes} <span>M</span></div>
      <div className="timer-element">{seconds} <span>S</span></div>
    </div>
  );
}


function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [logoTopLeft, setLogoTopLeft] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLogoTopLeft(true);
    }, 5000); // 5 seconds after loading

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Simulate a network request by setting a timeout
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // 2 seconds delay
  }, []);
  
  useEffect(() => {
    fetch("https://geeth-stories-be.el.r.appspot.com/status")
      .then((response) => response.text())
      .then((data) => {
        console.log("Status API Response:", data);
      })
      .catch((error) => {
        console.error("There was an error fetching the status API:", error);
      });
  }, []);

  const launchDate = new Date('2023-09-01T00:00:00'); // Change to your desired date
  return (
    <div className="App">
      {isLoading ? (
        <div className="pulse-loader">
          <div className="pulse-dot"></div>
          <div className="pulse-dot"></div>
          <div className="pulse-dot"></div>
        </div>
      ) : (
        <header className="App-header fade-in">
          <img
            src={logo}
            className={`App-logo ${logoTopLeft ? 'logo-top-left' : ''}`}
            alt="logo"
          />
          {logoTopLeft && <Slideshow />} {/* your slideshow component */}
          <CountdownTimer targetDate={launchDate} />
          <p className="mission-statement">
            At Geeth Stories, we celebrate the beauty of individual expression.
            Through our meticulously crafted portraits, frames, and unique gift items,
            we bring art into everyday life. Our mission is to connect people with art
            that speaks to their soul, created with passion and purpose.
          </p>
          <div className="instagram-container">
            <a
              className="instagram-link"
              href="https://www.instagram.com/geethstories/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram" aria-hidden="true"></i>
              <span className="follow-text">Follow Us on Instagram!</span>
            </a>
          </div>

        </header>
      )}
    </div>
  );
}

export default App;
