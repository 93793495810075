import React, { useEffect, useState } from 'react';
import './index.css';

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('../../assets/works', true, /\.(jpg|png)$/));

function SlideShow() {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000); // Change slides every 3 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="slide-show">
      <div className="slide-container">
        <img src={images[activeSlide]} alt={`slide ${activeSlide}`} className="slide-image" />
      </div>
    </div>
  );
}

export default SlideShow;
